import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from './base-url.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    url = this.api.endpoint;
    query = 'admin/orders';
    queryProfile = 'admin/profile';

    constructor(private http: HttpClient, private api: BaseUrlService) {}

    get(offset: number, filter: string = '') {
        return this.http.get(this.url + this.query + `?limit=10&offset=${offset}${filter}`, {
            headers: this.api.headers
        });
    }

    getOne(id: string) {
        return this.http.get(this.url + this.query + `/${id}`, { headers: this.api.headers });
    }

    export(filter: String = '') {
        return this.http.get(this.url + this.query + `/report?offset=0${filter}`, {
            headers: this.api.headers,
            responseType: 'blob'
        });
    }

    getFailItems(offset: number, filter: string = '') {
        return this.http.get(this.url + this.query + `/order-item?limit=10&offset=${offset}${filter}`, {
            headers: this.api.headers
        });
    }

    getFailItem(id: string) {
        return this.http.get(this.url + this.query + `/order-item/${id}`, {
            headers: this.api.headers
        });
    }

    modifyBindNumber(id: string, body) {
        return this.http.put(this.url + this.query + `/order-item/${id}`, body, { headers: this.api.headers });
    }

    rejectFailItem(id: string, body) {
        return this.http.put(this.url + this.query + `/order-item/reject/${id}`, body, { headers: this.api.headers });
    }

    getCertifications() {
        return this.http.get(this.url + 'certifications', { headers: this.api.headers });
    }

    getProfileModified(offset: number, query) {
        return this.http.get(
            this.url + this.queryProfile + '/modified-profile' + `?limit=10&offset=${offset}${query}`,
            {
                headers: this.api.headers
            }
        );
    }

    retryTransaction(orderId: string) {
        return this.http.post(
            this.url + 'v2/redo-binding',
            { orderId: Number(orderId) },
            {
                headers: this.api.headers
            }
        );
    }
}
